import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
	{ path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
	// { path: 'home-two', loadChildren: () => import('./home2/home-two.module').then(m => m.HomeTwoModule) },
	// { path: 'home-three', loadChildren: () => import('./home3/home-three.module').then(m => m.HomeThreeModule) },
	// { path: 'home-four', loadChildren: () => import('./home4/home-four.module').then(m => m.HomeFourModule) },
	// { path: 'home-five', loadChildren: () => import('./home5/home-five.module').then(m => m.HomeFiveModule) },
	{ path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
	{ path: 'awards-and-recognitions', loadChildren: () => import('./awards-and-recognitions/awards-and-recognitions.module').then(m => m.AwardsAndRecognitionsModule) },
	{ path: 'contact', loadChildren: () => import('./contactus/contactus.module').then(m => m.ContactusModule) },
	{ path: 'work/alain-class', loadChildren: () => import('./add-book/add-book.module').then(m => m.AddBookModule) },
	{ path: 'work/consult', loadChildren: () => import('./consult/consult.module').then(m => m.ConsultModule) },
	{ path: 'work/galvanize-insurance', loadChildren: () => import('./galvanize-insurance/galvanize-insurance.module').then(m => m.GalvanizeInsuranceModule) },
	{ path: 'work/giggers', loadChildren: () => import('./giggers/giggers.module').then(m => m.GiggersModule) },
	{ path: 'work/hfra', loadChildren: () => import('./hfra/hfra.module').then(m => m.HfraModule) },
	{ path: 'work/industry-insider', loadChildren: () => import('./industry-insider/industry-insider.module').then(m => m.IndustryInsiderModule) },
	{ path: 'work/somerset', loadChildren: () => import('./somerset/somerset.module').then(m => m.SomersetModule) },
	{ path: 'work/warniemoji', loadChildren: () => import('./warniemoji/warniemoji.module').then(m => m.WarniemojiModule) },
	{ path: 'work/worc', loadChildren: () => import('./worc/worc.module').then(m => m.WorcModule) },
	{ path: 'work/xgap', loadChildren: () => import('./xgap/xgap.module').then(m => m.XgapModule) },
	{ path: 'work/zuse-ar', loadChildren: () => import('./zuse-ar/zuse-ar.module').then(m => m.ZuseArModule) },
	{ path: 'work/afl-players', loadChildren: () => import('./afl-players/afl-players.module').then(m => m.AflPlayersModule) },
	{ path: 'work/globist', loadChildren: () => import('./globist/globist.module').then(m => m.GlobistModule) },
	{ path: 'work/freshfreshflowers', loadChildren: () => import('./freshfreshflowers/freshfreshflowers.module').then(m => m.FreshfreshflowersModule) },
	{ path: 'work/socialsounds', loadChildren: () => import('./socialsounds/socialsounds.module').then(m => m.SocialsoundsModule) },
	{ path: 'work/c-19tracker', loadChildren: () => import('./covid/covid.module').then(m => m.CovidModule) },
	{ path: 'work', loadChildren: () => import('./work/work.module').then(m => m.WorkModule) },
	{ path: 'work/lvmh', loadChildren: () => import('./lvmh/lvmh.module').then(m => m.LvmhModule) },

	{ path: 'testimonials', loadChildren: () => import('./testimonials/testimonials.module').then(m => m.TestimonialsModule) },

	// { path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
	// { path: 'blog/blog-detail', loadChildren: () => import('./blog-detail/blog-detail.module').then(m => m.BlogDetailModule) },
	{ path: 'services/website-design', loadChildren: () => import('./adwords/adwords.module').then(m => m.AdwordsModule) },
	{ path: 'services/mobileapps', loadChildren: () => import('./mobileapps/mobileapps.module').then(m => m.MobileappsModule) },
	{ path: 'lvmh', redirectTo: 'work/lvmh', pathMatch: 'full'},
	{ path: 'globist', redirectTo: 'work/globist', pathMatch: 'full'},
	{ path: 'xgap', redirectTo: 'work/xgap', pathMatch: 'full'},
	{ path: 'somerset', redirectTo: 'work/somerset', pathMatch: 'full'},
	{ path: 'galvanize-insurance', redirectTo: 'work/galvanize-insurance', pathMatch: 'full'},
	{ path: 'warniemoji', redirectTo: 'work/warniemoji', pathMatch: 'full'},
	{ path: 'alain-class', redirectTo: 'work/alain-class', pathMatch: 'full'},
	{ path: 'consult', redirectTo: 'work/consult', pathMatch: 'full'},
	{ path: 'giggers', redirectTo: 'work/giggers', pathMatch: 'full'},
	{ path: 'worc', redirectTo: 'work/worc', pathMatch: 'full'},
	{ path: 'afl-players', redirectTo: 'work/afl-players', pathMatch: 'full'},
	{ path: 'industry-insider', redirectTo: 'work/industry-insider', pathMatch: 'full'},
	{ path: 'website-design-development', redirectTo: 'work/website-design-development', pathMatch: 'full'},
	{ path: 'hfra', redirectTo: 'work/hfra', pathMatch: 'full'},
	{ path: 'c-19tracker', redirectTo: 'work/c-19tracker', pathMatch: 'full'},
	{ path: 'zuse-ar', redirectTo: 'work/zuse-ar', pathMatch: 'full'},
	{ path: 'home', redirectTo: '/', pathMatch: 'full'},
	{ path: 'mobile-apps', redirectTo: 'services/mobileapps', pathMatch: 'full'},


	{ path: 'work/href', redirectTo: 'work/hfra', pathMatch: 'full'},




	{ path: '**', redirectTo: 'page-not-found', pathMatch: 'full'},
	{ path: 'page-not-found', loadChildren: () => import('./404/url.module').then(m => m.UrlModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
		scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
