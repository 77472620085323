import { Injectable } from '@angular/core';

@Injectable()
export class Constants {

  mode: string;
  constructor() {
    this.mode = 'production'; //change 'dev' / 'staging',production
  }

  getApiUrl() {
    if (this.mode == 'dev') {
      return 'http://localhost:4007';
    } else if (this.mode == 'staging') {
      return 'http://nodejs.indianic.com:4007';
      //return 'http://10.2.1.49:4001/api/';
    } else if (this.mode == 'production') {
      // return 'http://ec2-13-54-226-117.ap-southeast-2.compute.amazonaws.com:4007/';
      return 'https://zusedigital.com';
      //return 'http://10.2.1.49:4001/api/';
    }else{
      return 'https://zusedigital.com';
    }
  }

  getWordPressApiUrl() {
    return 'https://zuseapi.zusedigital.com.au/wp-json/';
  }

  frontUrl() {
    // return 'assets';
    //return 'https://newzuse.zusedigital.com/';
    // return 'https://d36lr9hgdyglvn.cloudfront.net';  // TODO Old server URL S3 cloud front URL
    return 'https://d36gmzmklsuppb.cloudfront.net';  // TODO New server URL S3 cloud front URL
    //return "https://s3-ap-southeast-2.amazonaws.com/s3.zusedigital.com";
  }

}
