import { BrowserModule } from '@angular/platform-browser';
import { NgModule,  APP_INITIALIZER,
  ErrorHandler } from '@angular/core';
  import {
    Router
  } from "@angular/router";
import { HttpClientModule }    from '@angular/common/http';

import { Commonservices } from './commonservices';
import { DynamicScriptLoaderService } from './dynamic-script-loader.service';
import { Constants } from './constants';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent }         from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [Commonservices, Constants, DynamicScriptLoaderService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
