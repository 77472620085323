import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, NavigationEnd } from "@angular/router";
import { delay, map } from 'rxjs/operators';
import { Constants } from './constants';
import { Observable, of, Subject } from 'rxjs';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
declare var $: any;
import { Meta, Title } from '@angular/platform-browser';
declare var gtag: Function;

@Injectable()
export class Commonservices {
  private _apiUrl;
  public _siteUrl: any;
  private _apiWordpressUrl;
  router: Router;
  private previousUrl: string;
  private currentUrl: string;
  constructor(@Inject(DOCUMENT) private doc, private _meta: Meta, private _title: Title, router: Router, constants: Constants, @Inject(PLATFORM_ID) platformId: Object, private _http: HttpClient,
  ) {
    this.router = router;
    this._apiUrl = constants.getApiUrl();
    this._apiWordpressUrl = constants.getWordPressApiUrl();
    this.platformId = platformId;

    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });

    this._siteUrl = constants.getApiUrl();

  }

  public platformId;

  // Observable string sources
  private missionAnnouncedSource = new Subject<object>();
  private missionConfirmedSource = new Subject<object>();

  // Observable string streams
  missionAnnounced$ = this.missionAnnouncedSource.asObservable();
  missionConfirmed$ = this.missionConfirmedSource.asObservable();

  // Service message commands
  ChildToParent(mission: object) {
    this.missionAnnouncedSource.next(mission);
  }

  ParentToChild(astronaut: object) {
    this.missionConfirmedSource.next(astronaut);
  }

  getBaseUrl() {
    return this._apiUrl;
  }

  getPreviousUrl() {
    return this.previousUrl;
  }


  /****************************************************************************
  @PURPOSE      : To show validation message
  @PARAMETERS   : <field_name, errorObj?>
  @RETURN       : error message.
  ****************************************************************************/
  getErrorMessage(field, errorObj?) {
    let message = '';
    switch (field) {
      //Unauthorized access
      case 'unauthorized': { message = 'Logged out due to unauthorised access.' } break;

      //Login Form
      case 'email': {
        if (errorObj.required) { message = 'Please Enter Email.' }
        else if (errorObj.pattern) { message = 'Invalid Email Format.' }
      } break;
      case 'password': {
        if (errorObj.required) { message = 'Please Enter Password.' }
      } break;
      case 'confirmPassword': {
        if (errorObj.required) { message = 'Please Re-Enter Password.' }
      } break;

      //Default
      default: {
        message = ''
      }
    }
    return message;
  }
  /****************************************************************************/


  /****************************************************************************
  @PURPOSE      : Testing API
  @PARAMETERS   : n/a
  @RETURN       : <success_data>
  ****************************************************************************/
  getMessage(): Promise<any> {

    let headers = new HttpHeaders({ 'content-Type': 'application/json' });
    return new Promise((resolve, reject) => {
      this._http.get(this._apiUrl + '/api/' + 'getMessage', { headers })
        .subscribe(data => { resolve(data) }, error => { this.showServerError(error) })
    })


  }
  /****************************************************************************/

  /****************************************************************************
  @PURPOSE      : Add Project Inquery
  @PARAMETERS   : Project Inquery obj.
  @RETURN       : <success_data>
  ****************************************************************************/
  projectInquery(data): Promise<any> {

    let headers = new HttpHeaders({ 'content-Type': 'application/json' });
    return new Promise((resolve, reject) => {
      this._http.post(this._apiUrl + '/api/' + 'addProjectInquiry', data, { headers })
        .subscribe(data => { resolve(data) }, error => { this.showServerError(error) })
    })


  }
  /****************************************************************************/

  /****************************************************************************
  @PURPOSE      : Add Contact Inquery
  @PARAMETERS   : n/a
  @RETURN       : <success_data>
  ****************************************************************************/
  contactInquery(data): Promise<any> {

    let headers = new HttpHeaders({ 'content-Type': 'application/json' });
    return new Promise((resolve, reject) => {
      this._http.post(this._apiUrl + '/api/' + 'addContactInquiry', data, { headers })
        .subscribe(data => { resolve(data) }, error => { this.showServerError(error) })
    })


  }
  /****************************************************************************/

  /****************************************************************************
  @PURPOSE      : Get meta content.
  @PARAMETERS   : n/a
  @RETURN       : <success_data>
  ****************************************************************************/
  getMetaContent(): Promise<any> {

    let headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Cache-control': 'max-age=1314000',
      'Expires': 'The, 30 Dec 2019 23:59:59 GMT'
    });

    return new Promise((resolve, reject) => {
      this._http.get(this._apiUrl + '/api/' + 'getMetaSetting', { headers })
        .subscribe(data => { resolve(data) }, error => { this.showServerError(error) })
    })


  }

  isBrowser() {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }


  callApi(url, data, setting, method): Promise<any> {



    let headers = new HttpHeaders({ 'content-Type': 'application/json' });

    var apiurl = (setting) ? this._apiWordpressUrl + 'acf/v3/options/' + url : this._apiWordpressUrl + 'wp/v2/' + url;


    return new Promise((resolve, reject) => {
      if (method == 'post') {
        this._http.post(apiurl, data, { headers })
          .subscribe(data => { resolve(data) }, error => { this.showServerError(error) })
      } else if (method == 'get') {
        this._http.get(apiurl, { headers })
          .subscribe(data => { resolve(data) }, error => { this.showServerError(error) });
      }
    })

  }

  createLinkForCanonicalURL(success) {
    try {
      if (this.isBrowser()) {
        $("link[rel='canonical']").remove()
      }

      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', success.yoast_meta.yoast_wpseo_canonical);

      var ogfb = success.yoast_meta.yoast_wpseo_opengraph_image;
      var ogtw = success.yoast_meta.yoast_wpseo_twitter_image;

      this._title.setTitle(success.yoast_meta.yoast_wpseo_title);
      //this._meta.updateTag({ 'name': 'keywords', 'content': success.yoast_meta.yoast_wpseo_metakeywords });
      this._meta.updateTag({ 'name': 'description', 'content': success.yoast_meta.yoast_wpseo_metadesc });

      var ogurls = (success.yoast_meta && success.yoast_meta.yoast_wpseo_canonical) ? success.yoast_meta.yoast_wpseo_canonical : this._siteUrl;

      this._meta.updateTag({ "property": 'og:url', "content": ogurls });
      
      this._meta.updateTag({ "property": 'og:type', "content": 'website' });
      this._meta.updateTag({ "property": 'og:title', "content": success.yoast_meta.yoast_wpseo_opengraph_title });
      this._meta.updateTag({ "property": 'og:description', "content": success.yoast_meta.yoast_wpseo_opengraph_description });

      if (ogfb && ogtw) {
        console.log('1111111')
        this._meta.updateTag({ "property": 'og:image', "content": ogfb });
        this._meta.updateTag({ "name": 'twitter:image', "content": ogtw });
      } else if (ogfb) {
        console.log('22222')
        this._meta.updateTag({ "property": 'og:image', "content": ogfb });
        this._meta.updateTag({ "name": 'twitter:image', "content": ogfb });
      } else if (ogtw) {
        console.log('33333333')
        this._meta.updateTag({ "property": 'og:image', "content": ogtw });
        this._meta.updateTag({ "name": 'twitter:image', "content": ogtw });
      } else {
        if (success.featured_media > 0) {
          this.getFeaturedgtIm(success.featured_media)
        }
      }

      this._meta.updateTag({ "name": 'og:image:width', "content": '1200' });
      this._meta.updateTag({ "name": 'og:image:height', "content": '633' });
      //this._meta.updateTag({ "name": 'twitter:card', "content":  success.yoast_meta.yoast_wpseo_title});
      this._meta.updateTag({ "name": 'twitter:title', "content": success.yoast_meta.yoast_wpseo_twitter_title });
      this._meta.updateTag({ "name": 'twitter:description', "content": success.yoast_meta.yoast_wpseo_twitter_description });

      this._meta.updateTag({ "name": 'twitter:url', "content": ogurls });


      this.addGoogleTagManager(success.acf || {});
    } catch (error) {

    }

  }

  addGoogleTagManager(data) {
    try {
      //console.log("data",data)
      if (this.isBrowser()) {
        var googletag = data.gtag || null;
        var congtag = data.gtag_conversion || null;

        //if(data.gtag && data.gtag.length > 0){
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + googletag;
        document.head.appendChild(script1);

        const script2 = document.createElement('script');
        script2.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '`+ googletag + `');
                  `;
        document.head.appendChild(script2);

        const script3 = document.createElement('script');
        script3.innerHTML = `
                   gtag('event', 'conversion', {'send_to': '`+ googletag + `/-` + congtag + `'}); 
                  `;
        document.head.appendChild(script3);
        //}


        //***************************************************************************************//

        if (congtag && congtag.length > 0) {
          const scriptAMP1 = document.createElement('script');
          scriptAMP1.async = true;
          scriptAMP1['custom-element'] = "amp-analytics";
          scriptAMP1.src = 'https://cdn.ampproject.org/v0/amp-analytics-0.1.js';
          document.head.appendChild(scriptAMP1);

          var scriptAMP2: any = document.createElement('amp-analytics');
          scriptAMP2.type = "gtag";
          scriptAMP2["data-credentials"] = "include";
          //const scriptAMP3 = document.createElement('script');
          scriptAMP2.innerHTML = `
                    <script type="application/json"> 
                      { "vars": { "gtag_id": "`+ googletag + `", "config": { "` + googletag + `": { "groups": "default" } } }, "triggers": { "C_5HIXKF_7PtA": { "on": "visible", "vars": { "event_name": "conversion", "send_to": ["` + googletag + `/-` + congtag + `"] } } } } 
                    </script> 
                  `;

          //scriptAMP2.innerHTML = scriptAMP3;

          document.body.appendChild(scriptAMP2);

        } else {
          $("amp-analytics").remove();
          $("script[src='https://www.googletagmanager.com/gtag/js?id=" + googletag + "']").remove();
          $("script[src='https://cdn.ampproject.org/v0/amp-analytics-0.1.js']").remove();
          $('script').each(function () {
            var rf = $(this).text().search('gtag');
            if (rf > 0) {
              $(this).remove()
            }
          });
        }

      }
    } catch (error) {
      console.error('Error appending google analytics', error);
    }
  }

  getFeaturedgtIm(id) {
    var self = this;
    try {

      this.callApi('media/' + id, null, false, 'get').then(success => {

        this._meta.updateTag({ "property": 'og:image', "content": success.source_url });
        this._meta.updateTag({ "name": 'twitter:image', "content": success.source_url });

      });
    } catch (error) {
      console.log("error", error)
    }
  }

  showServerError(e) {

  }

  popToast(one, two, three) {

  }



}