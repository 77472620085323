import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { Commonservices } from './commonservices';
import { Subscription } from 'rxjs';
declare var $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  subscription: Subscription;

  constructor(public Commonservices: Commonservices, private _router: Router, private _meta: Meta, private _title: Title) {
    try {
      if (this.Commonservices.isBrowser()) {
        $(document).ready(function() {
          window.history.pushState(null, "", window.location.href);
          window.onpopstate = function() {
            window.history.pushState(null, "", window.location.href);
          };
        });
      }

      this.subscription = Commonservices.missionAnnounced$.subscribe(
        e => {
          this.showLoader();
        });
    } catch (error) {

    }
  }

  public meta: any = {
    meta_title: 'test title',
    meta_keywords: 'test keyword',
    meta_description: 'test desc'
  };

  ngOnInit() {
    if (this.Commonservices.isBrowser()) {
      $(window).on('popstate', $.proxy(function(event) {
        $('.back-main').removeClass('backdiv');
        console.log("backurl", this.Commonservices.getPreviousUrl())
        var routeUrl = this.Commonservices.getPreviousUrl();
        $('body').addClass('backactive');
        $('body').addClass('bodybefore');
        $('.back-main').css('width', $(window).width());
        $('.back-main').css('height', $(window).height());
        $('.back-main').css('top', '0');
        setTimeout($.proxy(function() {
          $('.back-main').css('top', '-100%');
          this._router.navigate([routeUrl]);
        }, this), 500);
        setTimeout(function() {
          $('.back-main').removeAttr('style');
        }, 1000);
        setTimeout(function() {
          $('body').removeClass('backactive');
        }, 1500);
        setTimeout(function() {
          console.log('11111111')
          $('body').removeClass('bodybefore');
        }, 1600);
      }, this));
    }
  }

  showLoader() {
    try {
      if (this.Commonservices.isBrowser()) {
        $('.back-main').removeClass('backdiv');
        console.log("backurl", this.Commonservices.getPreviousUrl())
        var routeUrl = this.Commonservices.getPreviousUrl();
        $('body').addClass('backactive');
        $('body').addClass('bodybefore');
        $('.back-main').css('width', $(window).width());
        $('.back-main').css('height', $(window).height());
        $('.back-main').css('top', '0');
        // setTimeout($.proxy(function () {
        //   $('.back-main').css('top', '-100%');
        //   //this._router.navigate([routeUrl]);
        // }, this), 500);
        // setTimeout(function () {
        //   $('.back-main').removeAttr('style');
        // }, 1000);
        // setTimeout(function () {
        //   $('body').removeClass('backactive');
        // }, 1500);
        // setTimeout(function () {
        //   console.log('11111111')
        //   $('body').removeClass('bodybefore');
        // }, 1600);
      }
    } catch (error) {

    }
  }



}
