import { Injectable } from '@angular/core';
declare var $:any;
import { Commonservices } from './commonservices';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'jquery', src: '../assets/js/jquery.min2.1.js' },
  { name: 'custom', src: '../assets/custom.js' },
  { name: 'easy-ticker', src: '../assets/js/jquery.easy-ticker.js' },
  { name: 'slick', src: '../assets/js/slick.js' },
  { name: 'mason', src: '../assets/js/mason.js' },
  { name: 'bxslider', src: '../assets/js/bx-slider.js' },
  { name: 'animate', src: '../assets/js/animate.js' },
  { name: 'one-adword', src: '../assets/js/one-adword.js' },
  { name: 'testimonialf', src: '../assets/js/testimonial.js' },
  { name: 'twin-max', src: '../assets/js/TweenMax.min2.1.3.js' },
  { name: 'form', src: '../assets/js/form.js' }
];

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor(public Commonservices:Commonservices,) {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    //console.log('1111111111111', scripts)
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      try{
        if (!this.scripts[name].loaded) {
          //load script
          let script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = this.scripts[name].src;
          //console.log("script", script)
          if (script.readyState) {  //IE
              script.onreadystatechange = () => {
                  if (script.readyState === "loaded" || script.readyState === "complete") {
                      script.onreadystatechange = null;
                      this.scripts[name].loaded = true;
                      resolve({script: name, loaded: true, status: 'Loaded'});
                  }
              };
          } else {  //Others
              script.onload = () => {
                  this.scripts[name].loaded = true;
                  resolve({script: name, loaded: true, status: 'Loaded'});
              };
          }
          script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
          document.getElementsByTagName('head')[0].appendChild(script);
        } else {
          resolve({ script: name, loaded: true, status: 'Already Loaded' });
        }
      }catch(error){

      }
      
    });
  }

  removeScript(src){
    var self = this;
    try{
      if(this.Commonservices.isBrowser()){
        $("script[src='../assets/js/"+src+".js']").remove();
        //console.log("self.scripts", self.scripts)
        self.scripts[src].loaded = false;
        //{script: name, loaded: true, status: 'Loaded'}
      }        
    }catch(error){
      console.log("error", error)
    }
  }

}